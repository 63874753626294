import React from "react";
import PropTypes from "prop-types";
import { Field, useFormikContext } from "formik";
import { TextInput, SelectInput, RadioGroupField } from "components/form";

const FormElement = ({ id, type, options, text, ...rest }) => {
  const { values } = useFormikContext();
  switch (type) {
    case "Yes/No":
      return (
        <Field
          component={RadioGroupField}
          name={`questions.${id}`}
          label={text}
          value={values[id]}
          options={[
            { value: true, text: "Yes" },
            { value: false, text: "No" }
          ]}
          {...rest}
        />
      );
    case "Multiple Choice":
      return (
        <div style={{ margin: "10px 0px" }}>
          <SelectInput
            name={`questions.${id}`}
            label={text}
            options={[{ text: "", value: undefined }, ...options]}
            style={{ marginTop: 10, marginBottom: 10 }}
            {...rest}
          />
          {(() => {
            const child = options.find(
              x => x.value === values.questions[id]
            )?.question;
            if (child != null)
              return (
                <FormElement
                  style={{ margin: 5, marginLeft: 20, width: "100%" }}
                  {...child}
                />
              );
          })()}
        </div>
      );
    case "Text":
      return (
        <div style={{ margin: "10px 0px" }}>
          <TextInput
            name={`questions.${id}`}
            type="text"
            label={text}
            id={`${id}-label`}
            style={{
              margin: 10,
              width: "100%"
              //margin: label?.length > 20 ? -1 : 5,
            }}
            {...rest}
          />
        </div>
      );
    case "Disclaimer":
      return (
        <div
          className="order-form-warning"
          style={{
            padding: "10px",
            border: "1px solid #ff3333",
            color: "#ff3333",
            background: "#ffcccc33",
            fontWeight: "600",
            margin: "10px 0"
          }}
          {...rest}
        >
          {text}
        </div>
      );
    case "Date":
      return (
        <Field
          type="date"
          name={`questions.${id}`}
          label={text}
          value={values[id]}
          {...rest}
        />
      );
    default:
      return null; //JSON.stringify({ type, options, text });
  }
};

FormElement.propTypes = {
  type: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
      ])
    })
  ),
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default FormElement;
