import React from "react";
import PropTypes from "prop-types";
import {
  TextInput,
  SelectInput,
  RadioGroupField,
  PhoneNumberInput
} from "components/form";
import { useFormikContext, Field } from "formik";
import useTranslate from "hooks/use-translate-language";
import { isValidPhone, isValidZip, normalizePhone } from "utils/misc";
import FormElement from "./form-elements";
import { STATES } from "utils/enum";

const PhysicianForm = ({ physician, questions }) => {
  const { values, setFieldValue } = useFormikContext();
  const translate = useTranslate();

  const last_saw_physician = questions.find(
    q => q.id === "last_seen_physician"
  );
  const physician_change_question = questions.find(
    q => q.id === "physician_change"
  );

  React.useEffect(() => {
    if (values.questions?.physician_change === false) {
      setFieldValue("physician", {
        ...physician,
        phone: normalizePhone(physician?.phone)
      });
    }
  }, [values.questions?.physician_change]);

  if (!physician_change_question) {
    return null;
  }
  const disabled = !values.questions?.physician_change;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        paddingTop: 15
      }}
    >
      {last_saw_physician && (
        <FormElement {...last_saw_physician} style={{ margin: 10 }} />
      )}
      <Field
        component={RadioGroupField}
        name="questions.physician_change"
        label={physician_change_question.text}
        value={values.questions.physician_change}
        size="small"
        options={[
          { value: true, text: "Yes" },
          { value: false, text: "No" }
        ]}
      />
      <div style={{ height: 20, marginBottom: 10 }}>
        {!disabled && (
          <sub style={{ color: "#444" }}>{translate("enterInformation")}</sub>
        )}
      </div>
      <div style={{ margin: 5, padding: 10 }}>
        <div
          style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 10 }}
        >
          <TextInput
            name="physician.name"
            label={translate("myCurrentPhysician")}
            style={{ marginBottom: 20 }}
            size="small"
            disabled={disabled}
          />
          <TextInput
            name="physician.npi"
            label="NPI #"
            style={{ marginBottom: 20 }}
            disabled={disabled}
            size="small"
            type="number"
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <div
            style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 10 }}
          >
            <TextInput
              name="physician.address.street_address"
              label={translate("streetAddress")}
              style={{ marginBottom: 10 }}
              size="small"
              disabled={disabled}
            />
            <TextInput
              name="physician.address.city"
              label={translate("city")}
              style={{ marginBottom: 10 }}
              size="small"
              disabled={disabled}
            />
            <TextInput
              name="physician.address.zip"
              type="number"
              label={translate("zip")}
              style={{ marginBottom: 10 }}
              size="small"
              disabled={disabled}
              validate={isValidZip}
            />
            <SelectInput
              name="physician.address.state"
              label={translate("state")}
              options={STATES}
              // style={{ marginBottom: 20 }}
              style={{ marginTop: 10, marginBottom: 10 }}
              size="small"
              disabled={disabled}
            />
          </div>
          <PhoneNumberInput
            name="physician.phone"
            type="tel"
            label={translate("physicianPhoneNumber")}
            disabled={disabled}
            size="small"
            validate={isValidPhone}
          />
          <PhoneNumberInput
            name="physician.fax"
            type="tel"
            label={translate("physicianFaxNumber")}
            size="small"
            disabled={disabled}
            validate={isValidPhone}
          />
        </div>
      </div>
    </div>
  );
};

export default PhysicianForm;

PhysicianForm.propTypes = {
  physician: PropTypes.object,
  questions: PropTypes.arrayOf(PropTypes.object).isRequired
};
