import React from "react";
import PropTypes from "prop-types";
import { Field, FieldArray, useFormikContext } from "formik";
import { TextInput, SelectInput, RadioGroupField } from "components/form";
import { formatDate } from "utils/misc";

const getInsuranceDisplayName = index => {
  switch (index) {
    case 0:
      return "Primary";
    case 1:
      return "Secondary";
    case 2:
      return "Tertiary";
    default:
      return "";
  }
};

// form_lists/insurance_relationship_types
const guarantorOptions = [
  "Self",
  "Spouse",
  "Child",
  "Other Adult",
  "Unspecified Dependent",
  "Cadaver Donor",
  "Organ Donor",
  "Employee",
  "Life Partner"
].map(x => ({
  text: x,
  value: x
}));

const InsuranceForm = ({ insuranceOptions, patientInsurances, questions }) => {
  const insurnace_change_question = questions.find(
    q => q.id === "insurance_change"
  );

  const { values, errors, setFieldValue } = useFormikContext();

  const disabled = !values.questions?.insurance_change;

  React.useEffect(() => {
    if (values.questions?.insurance_change === false) {
      setFieldValue("insurances", patientInsurances);
    }
  }, [values.questions?.insurance_change]);
  if (!insurnace_change_question) {
    return null;
  }
  return (
    <div>
      <Field
        component={RadioGroupField}
        name="questions.insurance_change"
        label="My insurance information has changed since my last order."
        value={values.questions.insurance_change}
        validate={x => (typeof x === "boolean" ? undefined : "Required")}
        options={[
          { value: true, text: "Yes" },
          { value: false, text: "No" }
        ]}
      />
      <FieldArray
        name="insurances"
        render={arrayHelpers => (
          <div style={{ marginTop: 20 }}>
            {values.insurances?.map((_, index) => (
              <div key={index} style={{ padding: 5 }}>
                {errors.insurances && errors.insurances[index] && (
                  <div style={{ color: "red" }}>
                    {errors.insurances[index].insurance_company_id}
                  </div>
                )}
                <SelectInput
                  name={`insurances.${index}.insurance_company_id`}
                  label={getInsuranceDisplayName(index) + " Insurance"}
                  options={[
                    { text: "", value: "", key: "" },
                    ...insuranceOptions
                  ]}
                  style={{ marginTop: 10, marginBottom: 10, marginLeft: 3 }}
                  value={values.insurances[index]?.insurance_company_id}
                  disabled={disabled}
                />
                {/* <SearchableDropdown */}
                {/*   options={[ */}
                {/*     { text: "", value: "", key: "" }, */}
                {/*     ...insuranceOptions */}
                {/*   ]} */}
                {/*   selected={values.insurances[index]?.insurance_company_id} */}
                {/*   disabled={disabled} */}
                {/*   onSelect={value => { */}
                {/*     const insuranceCompany = insuranceOptions?.find( */}
                {/*       x => x?.value === value */}
                {/*     ); */}
                {/*     setFieldValue(`insurances.${index}`, { */}
                {/*       ...values.insurances[index], */}
                {/*       insurance_company_id: value, */}
                {/*       insurance_company_name: insuranceCompany?.text, */}
                {/*       primary_order: index + 1, */}
                {/*       requires_dysfunctions: */}
                {/*         insuranceCompany?.requires_dysfunctions */}
                {/*     }); */}
                {/*   }} */}
                {/* /> */}
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    alignItems: "center",
                    gridGap: 10
                  }}
                >
                  <TextInput
                    name={`insurances.${index}.policy_number`}
                    type="text"
                    label="Policy Number"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    disabled={disabled}
                  />
                  <TextInput
                    name={`insurances.${index}.group_number`}
                    type="text"
                    label="Group Number"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    disabled={disabled}
                  />
                  <SelectInput
                    name={`insurances.${index}.guarantor_relation`}
                    component="select"
                    label="Guarantor Relationship"
                    InputLabelProps={{ shrink: true }}
                    style={{ marginTop: 10, marginBottom: 10, marginLeft: 3 }}
                    disabled={disabled}
                    size="small"
                    options={guarantorOptions}
                  />
                  <TextInput
                    name={`insurances.${index}.policy_start_date`}
                    type="date"
                    label="Policy Start Date"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    disabled={disabled}
                    value={formatDate(
                      values.insurances[index].policy_start_date,
                      "YYYY-MM-DD"
                    )}
                  />
                  {index > 0 && !disabled && (
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <button onClick={() => arrayHelpers.remove(index)}>
                        Delete
                      </button>
                    </div>
                  )}
                </div>
                {index == 0 && <hr style={{ margin: "5px 0px" }} />}
              </div>
            ))}
            {values.insurances.length == 1 && !disabled && (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <button
                  type="button"
                  style={{ margin: 0, padding: "5px 15px" }}
                  onClick={() =>
                    arrayHelpers.push({
                      insurance_company_id: "",
                      plan_number: "",
                      group_number: "",
                      guarantor_relation: "",
                      policy_start_date: "",
                      primary_order: values.insurances.length + 1
                    })
                  }
                >
                  Add Secondary Insurance
                </button>
              </div>
            )}
          </div>
        )}
      />
    </div>
  );
};

InsuranceForm.propTypes = {
  patientInsurances: PropTypes.arrayOf(PropTypes.object).isRequired,
  insuranceOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  questions: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default InsuranceForm;
